import { InputEmitter, StreamerStatus } from "@pureweb/platform-sdk";

import useAsyncEffect from "use-async-effect";
import { useEffect, useState } from "react";
import { Subject } from "rxjs";
import { useAzureAuthentication } from "./useAzureAuthentication";

require("dotenv").config();

const VALUE_LISTENER = "azureAuthExchange";

// interface UnrealLocalAuthExchangeContent {
//     firstName: string,
//     lastName: string,
//     email: string,
//     companyName: string
// }

export const useUnrealAzureAuthExchange = (emitter: InputEmitter, streamerStatus: StreamerStatus, messageSubject: Subject<string>, shouldRun: boolean) => {
  const { azureUserClaims, loadingAzureClaims } = useAzureAuthentication(true);
  

  useEffect(() => {

    if (!shouldRun) return;
    const emitContent = (content: any) => {
      console.log('AzureSend, send to unreal: ' + JSON.stringify(content));
      emitter.EmitUIInteraction(content);
    }
  
    const canEmit = (streamerStatus: StreamerStatus, emitter: InputEmitter): Boolean => {
      return Boolean(!loadingAzureClaims) && (!emitter || streamerStatus !== StreamerStatus.Connected);
    }

    if (canEmit(streamerStatus, emitter)) return;

    const subscription = messageSubject.subscribe(
      (value: string) => {
        try {
          let json = JSON.parse(value);

          if (json.type === VALUE_LISTENER) {

            if (typeof azureUserClaims !== "undefined") {
              console.log("azure sends" + azureUserClaims!.name + azureUserClaims!.preferred_username + azureUserClaims!.oid, azureUserClaims!.role  );
              emitContent({
                name: azureUserClaims!.name,
                email: azureUserClaims!.preferred_username,
                oid: azureUserClaims!.oid,
                role: azureUserClaims!.role
              });
            }
          }
        } catch (e) {
          console.error(e);
        }
      },
      (err: any) => {
        console.error(err);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [messageSubject, emitter, streamerStatus, azureUserClaims]);
};
